const emotionsConfig = [
    {
    title: '默认',
    type: 'image',
    content: [
        {
            alt: '[微笑]',
            src: require("../assets/emotions/0.gif")
        },
        {
            alt: '[瘪嘴]',
            src: require("../assets/emotions/1.gif")
        },
        {
            alt: '[色]',
            src: require("../assets/emotions/2.gif")
        },
        {
            alt: '[发呆]',
            src: require("../assets/emotions/3.gif")
        },
        {
            alt: '[得意]',
            src: require("../assets/emotions/4.gif")
        },
        {
            alt: '[流泪]',
            src: require("../assets/emotions/5.gif")
        },
        {
            alt: '[害羞]',
            src: require("../assets/emotions/6.gif")
        },
        {
            alt: '[闭嘴]',
            src: require("../assets/emotions/7.gif")
        },
        {
            alt: '[睡]',
            src: require("../assets/emotions/8.gif")
        },
        {
            alt: '[大哭]',
            src: require("../assets/emotions/9.gif")
        },
        {
            alt: '[尴尬]',
            src: require("../assets/emotions/10.gif")
        },
        {
            alt: '[发怒]',
            src: require("../assets/emotions/11.gif")
        },
        {
            alt: '[调皮]',
            src: require("../assets/emotions/12.gif")
        },
        {
            alt: '[呲牙]',
            src: require("../assets/emotions/13.gif")
        },
        {
            alt: '[惊讶]',
            src: require("../assets/emotions/14.gif")
        },
        {
            alt: '[难过]',
            src: require("../assets/emotions/15.gif")
        },
        {
            alt: '[酷]',
            src: require("../assets/emotions/16.gif")
        },
        {
            alt: '[冷汗]',
            src: require("../assets/emotions/17.gif")
        },
        {
            alt: '[抓狂]',
            src: require("../assets/emotions/18.gif")
        },
        {
            alt: '[吐]',
            src: require("../assets/emotions/19.gif")
        },
        {
            alt: '[偷笑]',
            src: require("../assets/emotions/20.gif")
        },
        {
            alt: '[愉快]',
            src: require("../assets/emotions/21.gif")
        },
        {
            alt: '[白眼]',
            src: require("../assets/emotions/22.gif")
        },
        {
            alt: '[傲慢]',
            src: require("../assets/emotions/23.gif")
        },
        {
            alt: '[饥饿]',
            src: require("../assets/emotions/24.gif")
        },
        {
            alt: '[困]',
            src: require("../assets/emotions/25.gif")
        },
        {
            alt: '[惊恐]',
            src: require("../assets/emotions/26.gif")
        },
        {
            alt: '[流汗]',
            src: require("../assets/emotions/27.gif")
        },
        {
            alt: '[憨笑]',
            src: require("../assets/emotions/28.gif")
        },
        {
            alt: '[悠闲]',
            src: require("../assets/emotions/29.gif")
        },
        {
            alt: '[奋斗]',
            src: require("../assets/emotions/30.gif")
        },
        {
            alt: '[咒骂]',
            src: require("../assets/emotions/31.gif")
        },
        {
            alt: '[疑问]',
            src: require("../assets/emotions/32.gif")
        },
        {
            alt: '[嘘]',
            src: require("../assets/emotions/33.gif")
        },
        {
            alt: '[晕]',
            src: require("../assets/emotions/34.gif")
        },
        {
            alt: '[疯了]',
            src: require("../assets/emotions/35.gif")
        },
        {
            alt: '[哀]',
            src: require("../assets/emotions/36.gif")
        },
        {
            alt: '[骷髅]',
            src: require("../assets/emotions/37.gif")
        },
        {
            alt: '[敲打]',
            src: require("../assets/emotions/38.gif")
        },
        {
            alt: '[再见]',
            src: require("../assets/emotions/39.gif")
        },
        {
            alt: '[擦汗]',
            src: require("../assets/emotions/40.gif")
        },
        {
            alt: '[抠鼻]',
            src: require("../assets/emotions/41.gif")
        },
        {
            alt: '[鼓掌]',
            src: require("../assets/emotions/42.gif")
        },
        {
            alt: '[糗大了]',
            src: require("../assets/emotions/43.gif")
        },
        {
            alt: '[坏笑]',
            src: require("../assets/emotions/44.gif")
        },
        {
            alt: '[左哼哼]',
            src: require("../assets/emotions/45.gif")
        },
        {
            alt: '[右哼哼]',
            src: require("../assets/emotions/46.gif")
        },
        {
            alt: '[哈欠]',
            src: require("../assets/emotions/47.gif")
        },
        {
            alt: '[鄙视]',
            src: require("../assets/emotions/48.gif")
        },
        {
            alt: '[委屈]',
            src: require("../assets/emotions/49.gif")
        },
        {
            alt: '[快哭了]',
            src: require("../assets/emotions/50.gif")
        },
        {
            alt: '[阴险]',
            src: require("../assets/emotions/51.gif")
        },
        {
            alt: '[亲亲]',
            src: require("../assets/emotions/52.gif")
        },
        {
            alt: '[吓]',
            src: require("../assets/emotions/53.gif")
        },
        {
            alt: '[可怜]',
            src: require("../assets/emotions/54.gif")
        },
        {
            alt: '[菜刀]',
            src: require("../assets/emotions/55.gif")
        },
        {
            alt: '[西瓜]',
            src: require("../assets/emotions/56.gif")
        }
    ]
},
    {
        // tab 的标题
        title: 'emoji',
        // type -> 'emoji' / 'image'
        type: 'emoji',
        // content -> 数组
        content: ['😀', '😃', '😄', '😁', '😆']
    }
]

export default emotionsConfig;